import { fatchLogin, fatchLogout, fatchUserInfo } from '@/api/login'
import { message, notification } from 'ant-design-vue'
import { setItem, clearStorage } from '@/utils/storage'
import { filterRequestRoutes } from '@/utils'
import Layout from '@/layout'

const state = () => ({
  nickname: '',
  name: '',
  role: '',
  roleName: '',
  phone: '',
  menuList: [],
})
const getters = {
  nickname: (state) => state.nickname,
  name: (state) => state.name,
  role: (state) => state.role,
  roleName: (state) => state.roleName,
  phone: (state) => state.phone,
  menuList: (state) => state.menuList,
}
const mutations = {
  setUsername(state, { nickName, name }) {
    state.nickname = nickName
    state.name = name
  },
  setRole(state, roleKey) {
    state.role = roleKey
  },
  setRoleName(state, roleName) {
    state.roleName = roleName
  },
  setPhone(state, phone) {
    state.phone = phone
  },
  setMenuList(state, list) {
    state.menuList = list
  },
}
const actions = {
  async Login({ commit }, userInfo) {
    return new Promise(async (resolve, reject) => {
      try {
        const requestRes = await fatchLogin(userInfo)
        if (requestRes.status === 0) {
          commit('setUsername', requestRes.data)
          commit('setPhone', requestRes.data.phone)
          setItem('role', requestRes.data.roleKey)
          resolve(requestRes)
        } else {
          reject(requestRes)
        }
      } catch (err) {
        reject(err)
      }
    })
  },
  async Logout({ dispatch }) {
    return new Promise((resolve, reject) => {
      fatchLogout()
        .then(async (res) => {
          if (res.status === 0) {
            await dispatch('resetAll')
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          console.log('er:', err)
          reject(err)
        })
    })
  },
  GetInfo({ commit }) {
    return new Promise((resolve, reject) => {
      // 调接口 获取用户信息及菜单
      fatchUserInfo()
        .then((res) => {
          if (res.status === 0) {
            const requestData = res.data
            // 处理菜单数据
            const filterRouters = filterRequestRoutes(requestData.menuList || [])
            commit('setUsername', {
              nickName: requestData.nickname,
              name: requestData.name,
            })
            commit('setRoleName', requestData.roleName)
            commit('setPhone', requestData.phone)
            resolve({
              filterRouters: [
                {
                  path: '/',
                  component: Layout,
                  meta: {
                    title: '数据统计',
                  },
                  name: 'dash',
                  redirect: '/dashboard',
                  children: [
                    {
                      path: '/dashboard',
                      name: 'Dashboard',
                      component: () => import('@/views/dashboard/index.vue'),
                      meta: { title: '数据面板' },
                    },
                  ],
                },
                ...filterRouters,
              ],
              role: requestData.roleKey,
            })
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async resetAll({ commit, dispatch }) {
    clearStorage()
    commit('setUsername', {
      nickName: '',
      name: '',
    })
    commit('setRole', '')
    commit('setPhone', '')
    commit('setMenuList', [])
  },
}
export default { state, getters, mutations, actions }
